import React from 'react';
import Layout from '../components/layouts/Layout';

export default function notFound() {
  return (
    <Layout>
      <h1>404, page not found</h1>
    </Layout>
  );
}
